

export default function Vaults() {
    return (
      <section className="py-1 text-gray-800 dark:text-white">
        <div className="w-full xl:w-10/12 px-4 mx-auto mt-24">

          <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800">
            <span className="block rounded-lg bg-white dark:bg-[#0F172A] text-sm font-medium dark:text-white">    
              <div className="relative flex flex-col min-w-0 break-words w-full rounded bg-white dark:bg-transparent">
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                      <h3 className="font-semibold text-base"> XEN Vaults </h3>
                    </div>
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                      <button className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"> See all </button>
                    </div>
                  </div>
                </div>
                <div className="block w-full overflow-x-auto">
                  <table className="items-center w-full border-collapse">
                    <thead className="thead-light ">
                      <tr>
                        <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"> Term </th>
                        <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"> Due Date </th>
                        <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"> Amount </th>
                        <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"> Progress </th>
                        <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"></th>
                      </tr>
                    </thead>
                    <tbody>
      
                      <tr>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> 1 </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 "> 2023-10-30T23:37:13.000Z </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <div className="flex items-center">
                            <span className="mr-2">5%</span>
                            <div className="relative w-full">
                              <div className="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                                <div style={{"width": "5%"}} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"></div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                        <button type="button" className="inline-flex items-center justify-center py-2 px-3 rounded-lg text-gray-800 dark:text-gray-200 hover:text-green-500 text-sm font-semibold transition">
                            Unlock Early
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> 1 </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 "> 2023-10-30T23:37:13.000Z </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <div className="flex items-center">
                            <span className="mr-2">95%</span>
                            <div className="relative w-full">
                              <div className="overflow-hidden h-2 text-xs flex rounded bg-green-200">
                                <div style={{"width": "95%"}} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <button type="button" className="inline-flex items-center justify-center py-2 px-3 rounded-lg text-gray-800 dark:text-gray-200 hover:text-green-500 text-sm font-semibold transition">
                            Unlock Early
                          </button>
                        </td>
                      </tr>
      
                    </tbody>
                  </table>
                </div>
              </div>
            </span>
          </div>

        </div>
      </section>
    )
  }
