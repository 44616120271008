import React, { useRef, useState, useContext } from 'react'
import { WalletContext } from '../context/WalletContext'

import ExampleButton from './custombutton'
import { useAccount, useConnect } from "wagmi"

export default function Header () {
  const { wallet, hasProvider } = useContext(WalletContext)
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  console.log(isConnected)
  console.log(address)

  return (
    <div className="fixed w-full z-30 flex bg-white dark:bg-[#0F172A] p-2 items-center justify-center h-16 pl-10">
      <div className="logo ml-12 dark:text-white transform ease-in-out duration-500 flex-none h-full flex items-center justify-center">
        XenVault
      </div>
      <div className="grow h-full flex items-center justify-center"></div>

      <div className="flex-none h-full text-center flex items-center justify-center">
        <div className="flex space-x-3 items-center md:px-3">
          <div className="hidden md:hidden">Balance: 0.000 ETH</div>
          <div className="flex-none flex justify-center">

            <ExampleButton />

          </div>
        </div>
      </div>
    </div>
  )
}
