import React, { useEffect, useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function Breadcrumb () {

  // useEffect(() => {
  //   function checkLocation() {
  //     console.log(window.location)
  //   }

  //   checkLocation()
  // }, [])


  const location = useLocation()
  // const [breadCrumbData, setBreadCrumbData] = useState([]);

  // const getBreadCrumbData = async () => {
  //     // const breadCrumbHeaderResponse = await API.fetchBreadCrumbHeader(location.pathname);
  //     // console.log(window.location.pathname)
  //     // setBreadCrumbData(breadCrumbHeaderResponse);
  // };

  // useEffect(() => {
  //     // getBreadCrumbData();
  // }, [location.pathname]); // <==== here

  function displaySection (path) {
    switch(path) {
      case '/': {
        return (
          <li>
            <div className="flex items-center">
              <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to="/" className="ml-1 text-sm font-medium hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                Lock Xen
              </Link>
            </div>
          </li>
        );
        break;
      }

      case '/vaults': {
        return (
          <li>
            <div className="flex items-center">
              <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to="/vaults" className="ml-1 text-sm font-medium hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                Vaults
              </Link>
            </div>
          </li>
        )
        break;
      }

      case '/info': {

      }
    }
  }

  return (
    <nav className="flex px-5 py-3 rounded-lg bg-gray-200 dark:bg-[#1E293B]" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        <li className="inline-flex items-center">
          <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
          <Link to="/" className="inline-flex items-center text-sm font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
            Home
          </Link>
        </li>

        {displaySection(window.location.pathname)}

      </ol>
    </nav>
  )
}
