
import './App.css'
import React, { useContext, useEffect, useRef, useState, Suspense, lazy } from 'react'

import Breadcrumb from './components/breadcrumb'
import Sidebar from './components/sidebar'
import Header from './components/header'

import Vaults from './pages/vaults'
import VaultLock from './pages/vaultlock'
import Page404 from './pages/404'

import { Route, Routes } from 'react-router-dom';

import { truncateEthAddress, formatBalance, formatChainAsNum } from './lib/utils'
const { ethers, JsonRpcProvider } = require('ethers')

const xen_abi = require('./assets/xen.abi.json')
const xen_address = '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512'.toLowerCase()

const vault_abi = require('./assets/vault.abi.json')
const vault_address = '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0'.toLowerCase()

function App() {
  return (
    <Suspense>
      <div className="body bg-white dark:bg-[#0F172A]">
        <Header />
        <Sidebar />

        <div className="content ml-12 transform ease-in-out duration-500 pt-20 px-2 md:px-5 pb-4">
          <Breadcrumb />

          <Routes>
            <Route path="/" element={<VaultLock />} />
            <Route path="/vaults" element={<Vaults />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </div>

      </div>
    </Suspense>
  );
}

export default App;
