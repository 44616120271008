
import { createContext, useEffect, useState } from 'react'
import { truncateEthAddress, formatBalance, formatChainAsNum } from '../lib/utils'

// const { chains, publicClient, webSocketPublicClient } = configureChains(
//   [localhost, mainnet, polygon, optimism, arbitrum],
//   [publicProvider()],
// )

import { WagmiConfig, createConfig } from "wagmi";
import { ConnectKitProvider, ConnectKitButton, getDefaultConfig } from "connectkit";
import { localhost, mainnet, polygon, optimism, arbitrum } from 'wagmi/chains';
localhost.id = 31337
const chains = [localhost, mainnet, polygon, optimism, arbitrum]
const config = createConfig(
  getDefaultConfig({
    // Required API Keys
    // alchemyId: process.env.ALCHEMY_ID, // or infuraId
    walletConnectProjectId: '4fbf148060f31141f7d503bd3ed5d366',
    chains,

    // Required
    appName: "XenVault",

    // Optional
    appDescription: "XenVault",
    appUrl: "https://app.xenvault.com", // your app's url
    appIcon: "https://app-dev.xenvault.com/static/media/safe-box-purple.5e97d17e9527136c56b2.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
  }),
);



export const WalletContext = createContext()

export const WalletProvider = ({ children }) => {
  const [hasProvider, setHasProvider] = useState('my_special_value')
  const [wallet, setWallet] = useState({"foo":"bar"})

  return (
    <WalletContext.Provider 
      value={{
        wallet,
        hasProvider
      }}>
      <WagmiConfig config={config}>
        <ConnectKitProvider>
          {children}
        </ConnectKitProvider>
      </WagmiConfig>
    </WalletContext.Provider>
  )
}

