import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import {BrowserRouter as Router} from 'react-router-dom';

import './assets/main.css'
import App from './App'

import { ThemeProvider } from './context/ThemeContext'
import { WalletProvider } from './context/WalletContext'

const root = createRoot(document.getElementById('root'))
root.render(
  <StrictMode>
    <ThemeProvider>
      <Router>
        <WalletProvider>
          <App />
        </WalletProvider>
      </Router>
    </ThemeProvider>
  </StrictMode>
)
