import { ConnectKitButton } from "connectkit";

export default function ExampleButton() {
  return (
    <ConnectKitButton.Custom>
      {({ isConnected, show, truncatedAddress, ensName }) => {
        return (
          <a onClick={show} className="cursor-pointer inline-block rounded bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center mt-8">
            <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">
              {isConnected ? ensName ?? truncatedAddress : "Connect Wallet"}
            </span>
          </a>
        );
      }}
    </ConnectKitButton.Custom>
  );
};